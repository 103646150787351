import { graphql, useStaticQuery } from "gatsby"

import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import React from "react"
import { useLocation } from "@reach/router"

const query = graphql`
  query {
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`

const SEO = ({ seo, language }) => {
  const {
    wp: { generalSettings },
  } = useStaticQuery(query)
  const { href, origin } = useLocation()

  if (!seo.title || seo.title === "") {
    seo.title = generalSettings.title
  }

  if (!seo.metaDesc || seo.metaDesc === "") {
    seo.metaDesc = generalSettings.description
  }

  if (!seo.opengraphImage) {
    seo.opengraphImage = { sourceUrl: `${origin}/social.png` }
  }

  seo.canonical = href

  // INTEGRATE MORE META TAGS - https://gist.github.com/lancejpollard/1978404
  // INTEGRATE JSON structured data - https://developers.google.com/search/docs/guides/intro-structured-data
  // Test structured data in development - https://search.google.com/structured-data/testing-tool/u/0/

  return (
    <Helmet>
      <html lang={language} />
      <meta charset="UTF-8" />
      <title>{seo.title}</title>
      {seo.canonical && <link rel="canonical" href={seo.canonical} />}
      {seo.metaDesc && <meta name="description" content={seo.metaDesc} />}
      {seo.metaKeywords && <meta name="keywords" content={seo.metaKeywords} />}
      <meta name="copyright" content="Hilt Agency" />
      <link rel="preconnect" href={process.env.GATSBY_API_ENDPOINT} />
      <meta name="language" content={language} />
      <meta name="author" content="fabioruolo, hello@fabioruolo.com" />
      <meta
        name="designer"
        content="Quentin Villeret, hello@quentinvilleret.com"
      />
      {seo.noIndex && <meta name="robots" content="noindex" />}
      {seo.canonical && <meta property="og:url" content={seo.canonical} />}
      {seo.ogType ? (
        <meta property="og:type" content={seo.ogType} />
      ) : (
        <meta property="og:type" content="website" />
      )}
      {seo.opengraphTitle && (
        <meta property="og:title" content={seo.opengraphTitle} />
      )}
      {seo.opengraphDescription && (
        <meta property="og:description" content={seo.opengraphDescription} />
      )}
      {seo?.opengraphImage && (
        <meta
          property="og:image"
          content={
            seo.opengraphImage.sourceUrl.includes("http")
              ? seo.opengraphImage.sourceUrl
              : `${process.env.GATSBY_API_ENDPOINT}${seo.opengraphImage.sourceUrl}`
          }
        />
      )}

      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={post.author.twitter} /> */}
      {seo.twitterTitle && (
        <meta name="twitter:title" content={seo.twitterTitle} />
      )}
      {seo.twitterDescription && (
        <meta name="twitter:description" content={seo.twitterDescription} />
      )}
      {seo.twitterImage && (
        <meta name="twitter:image" content={seo.twitterImage.sourceUrl} />
      )}
    </Helmet>
  )
}

SEO.propTypes = {
  seo: PropTypes.object,
  language: PropTypes.string,
}

SEO.defaultProps = {
  language: "en-GB",
}

export default SEO
